module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-b9429a2d22/0/cache/gatsby-plugin-manifest-npm-3.7.0-1f4c1df079-1cc939b027.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yarn","short_name":"yarn","start_url":"/","background_color":"#2188b6","theme_color":"#2188b6","display":"minimal-ui","icon":"src/images/yarn-kitten.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"775b53071ebde4f6d738805a2d9fcb72"},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-plugin-mdx-virtual-49d5bbce15/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/builds/BootPM/www.yarnpkg.cn/.yarn/cache/gatsby-remark-table-of-contents-npm-1.0.0-4036650436-ce6600b023.zip/node_modules/gatsby-remark-table-of-contents/dist/gatsby-remark-table-of-contents.cjs.js"},{"resolve":"/builds/BootPM/www.yarnpkg.cn/.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-c7ea295503/0/cache/gatsby-remark-autolink-headers-npm-4.4.0-0156526a76-cb163c0a38.zip/node_modules/gatsby-remark-autolink-headers/index.js","options":{"offsetY":"100"}},{"resolve":"/builds/BootPM/www.yarnpkg.cn/.yarn/__virtual__/gatsby-remark-prismjs-virtual-b218d24718/0/cache/gatsby-remark-prismjs-npm-5.4.0-d1df40d952-d68f7301ba.zip/node_modules/gatsby-remark-prismjs/index.js"}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/BootPM/www.yarnpkg.cn/packages/gatsby"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-1db266ee51/0/cache/gatsby-plugin-catch-links-npm-3.7.0-ced13170c1-65d12a5725.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"^\\/(api|playground)$"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
